/// <reference path='../typings.d.ts' />

import { DatePipe } from '@angular/common'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { ErrorHandler, NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar'
import { MatToolbarModule } from '@angular/material/toolbar'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouterModule } from '@angular/router'
import { AuthHttpInterceptor, AuthModule as Auth0AuthModule } from '@auth0/auth0-angular'
import { GoogleTagManagerModule } from 'angular-google-tag-manager'

import { Auth0Environment, environment } from '../environments/environment'

import { AppComponent } from './app.component'
import { ROUTES } from './app.routes'
import { AuthCallbackComponent } from './auth-callback/auth-callback.component'
import { AuthModule } from './auth/auth.module'
import { BusinessTokenInterceptor } from './auth/interceptors/business-token.interceptor'
import { LoggingService } from './auth/services'
import { LiquidCommonModule } from './common/liquid-common.module'
import { CoreModule } from './core/core.module'
import { BusinessTokenExpirationInterceptor } from './core/interceptors/business-token-expiration.interceptor'
import { SiteStatusInterceptor } from './core/interceptors/site-status.interceptor'
import { UserTimeZoneInterceptor } from './core/interceptors/user-timezone.interceptor'
import { DialogModule, DialogService } from './dialogs'
import { FullStoryComponent } from './external/full-story/full-story.component'
import { GoogleAnalyticsComponent } from './external/google-analytics/google-analytics.component'
import { GoogleTagManagerComponent } from './external/google-tag-manager/google-tag-manager.component'
import { HubspotComponent } from './external/hubspot/hubspot.component'
import { SleekPlanComponent } from './external/sleek-plan/sleek-plan.component'
import { RootComponent } from './modules/root/root.component'
import { SharedModule } from './modules/shared/shared.module'
import { SiteModule } from './modules/site/site.module'
import { WorkOrdersCommonModule } from './modules/work-orders-common/work-orders-common.module'
import { BillingInvoiceStatusColorPipe, BusinessStatusColorPipe, BusinessStatusLabelPipe, ChipOptionPipe, EntityTypePipe, HighlightMatchingTextPipe, MoneyMaskPipe, SafeHtmlPipe } from './pipes'
import { SignOutComponent } from './sign-out/sign-out.component'
import { SignUpComponent } from './sign-up/sign-up.component'
import { SystemHealthInterceptor } from './system-health/interceptors/system-health.interceptor'
import { SystemHealthModule } from './system-health/system-health.module';

@NgModule({
    bootstrap: [
        AppComponent,
    ],
    declarations: [
        AppComponent,
        AuthCallbackComponent,
        FullStoryComponent,
        HubspotComponent,
        RootComponent,
        SleekPlanComponent,
        SignUpComponent,
        SignOutComponent,
        GoogleAnalyticsComponent,
        GoogleTagManagerComponent,
    ],
    imports: [
        AuthModule,
        Auth0AuthModule.forRoot(Auth0Environment),
        BrowserAnimationsModule,
        BrowserModule,
        CoreModule.forRoot(),
        DialogModule,
        FlexLayoutModule,
        FormsModule,
        HttpClientModule,
        LiquidCommonModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatToolbarModule,
        ReactiveFormsModule,
        RouterModule.forRoot(ROUTES, {
            useHash: false,
            onSameUrlNavigation: 'reload',
            relativeLinkResolution: 'legacy',
        }),
        SharedModule,
        SiteModule,
        SystemHealthModule,
        WorkOrdersCommonModule,
        GoogleTagManagerModule.forRoot({
            id: environment.googleTagManager.id,
            // gtm_auth: YOUR_GTM_AUTH,
            // gtm_preview: YOUR_GTM_ENV
        }),
    ],
    providers: [
        BillingInvoiceStatusColorPipe,
        BusinessStatusColorPipe,
        BusinessStatusLabelPipe,
        ChipOptionPipe,
        DatePipe,
        DialogService,
        EntityTypePipe,
        HighlightMatchingTextPipe,
        MoneyMaskPipe,
        SafeHtmlPipe,
        { provide: ErrorHandler, useFactory: LoggingService.initialize },
        { provide: HTTP_INTERCEPTORS, useClass: SiteStatusInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: BusinessTokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: BusinessTokenExpirationInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: SystemHealthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: UserTimeZoneInterceptor, multi: true },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'auto', appearance: 'standard' } },
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { panelClass: 'lqd-snack-bar', duration: 2000 } },
    ],
})
export class AppModule { }
