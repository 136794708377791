import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { interval } from 'rxjs'
import { map, switchMap, takeWhile, tap } from 'rxjs/operators'
import { StorageKeyAuth } from '../../auth/models'

import { StorageKey } from '../../common/global/storage-key.enum'
import { SiteStatusService, UrlService } from '../../core/services'
import { DialogService } from '../../dialogs'
import { SystemHealthService } from '../services'

@Component({
    selector: 'app-system-down',
    templateUrl: './system-down.component.html',
    styleUrls: ['./system-down.component.scss'],
})
export class SystemDownComponent implements OnInit {

    trustModalVisible = false;
    showTrustModal() { this.trustModalVisible = true; }
    closeTrustModal() { this.trustModalVisible = false; }

    readonly liquidStatusUrl: string = this.urls.external.liquidStatusUrl
    isMaintenance: boolean = false
    maintenanceBegin: number
    maintenanceUntil: number

    constructor(
        private dialogs: DialogService,
        private status: SiteStatusService,
        private systemHealth: SystemHealthService,
        private router: Router,
        private urls: UrlService,
    ) { }

    ngOnInit(): void {
        // close any open dialogs
        this.dialogs.close()
        const status: string = localStorage.getItem(this.status.SiteStatusKey)
        const statusBegin: number = +localStorage.getItem(this.status.SiteStatusBeginKey)
        const statusUntil: number = +localStorage.getItem(this.status.SiteStatusUntilKey)

        this.isMaintenance = !!status && status.toLowerCase() === 'maintenance'
        if (this.isMaintenance) {
            this.maintenanceBegin = statusBegin
            this.maintenanceUntil = statusUntil
        }

        interval(10000)
            .pipe(
                switchMap(() => this.systemHealth.getSystemHealth()),
                map(response => !response),
                tap((isDown) => {
                    if (!isDown) {
                        const lastPath: string = localStorage.getItem(StorageKeyAuth.REDIRECT_ON_AUTH)
                        this.router.navigateByUrl(lastPath ?? this.urls.route.home())
                    }
                }),
                takeWhile(isDown => isDown === true),
            )
            .subscribe()
    }

}
